<template>
  <el-card class="box-card">
    <el-row style="margin-top: 20px" :gutter="20">
      <el-col :span="4">
        <el-image :src="url" :preview-src-list="url"/>
      </el-col>
      <el-col :span="20">
        <el-card shadow="hover">
          <el-form ref="form" label-width="90px" :model="form" :rules="rules">
            <el-tabs v-model="activeName">
              <el-tab-pane label="奖品" name="1">
                <el-tabs v-model="prize_name" :tab-position="tabPosition">
                  <el-tab-pane label="奖品1" name="1"></el-tab-pane>
                  <el-tab-pane label="奖品2" name="2"></el-tab-pane>
                  <el-tab-pane label="奖品3" name="3"></el-tab-pane>
                  <el-tab-pane label="奖品4" name="4"></el-tab-pane>
                  <el-tab-pane label="奖品5" name="5"></el-tab-pane>
                  <el-tab-pane label="奖品6" name="6"></el-tab-pane>
                  <el-tab-pane label="奖品7" name="7"></el-tab-pane>
                  <el-tab-pane label="奖品8" name="8"></el-tab-pane>
                  <span>
                <el-form
                    ref="dataObj"
                    label-width="80px"
                    :loading="prize_loading"
                    :model="dataObj"
                    :rules="dataRules"
                    style="margin-top: 20px"
                >
                  <el-form-item label="奖品名称" prop="name">
                    <el-input
                        v-model="dataObj.name"
                        size="small"
                        placeholder="请输入文件路径"
                        style="width: 40%"
                    />
                  </el-form-item>
                  <el-form-item label="奖品类型" prop="type">
                    <el-radio-group v-model="dataObj.type" size="small">
                      <el-radio-button
                          v-for="member in radioArr"
                          :key="member.index"
                          :label="member.index"
                      >
                        {{ member.name }}
                      </el-radio-button>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item
                      v-show="dataObj.type == 3"
                      label="优惠券"
                      prop="prize"
                  >
                    <el-select
                        v-model="dataObj.prize"
                        filterable
                        style="width: 70%"
                        @focus="getCouponList"
                    >
                      <el-option
                          v-for="item in couponArr"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item
                      v-show="dataObj.type == 6"
                      label="套餐"
                      prop="prize"
                  >
                    <el-select
                        v-model="dataObj.prize"
                        filterable
                        size="small"
                        style="width: 40%"
                        @focus="getMealList"
                    >
                      <el-option
                          v-for="item in mealArr"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item
                      v-show="
                      dataObj.type != 3 &&
                      dataObj.type != 1 &&
                      dataObj.type != 5 &&
                      dataObj.type != 6
                    "
                      label="金额/积分"
                      prop="prize"
                  >
                    <el-input
                        v-model="dataObj.prize"
                        size="small"
                        placeholder="请输入金额/积分"
                        style="width: 40%"
                        type="number"
                    />
                  </el-form-item>
                  <el-form-item
                      v-show="dataObj.type != 1"
                      label="库存"
                      prop="stock"
                  >
                    <el-input
                        v-model="dataObj.stock"
                        placeholder="请输入库存"
                        size="small"
                        style="width: 40%"
                        type="number"
                    />
                  </el-form-item>
                  <el-form-item label="缩略图" prop="img">
                    <el-input
                        v-model="dataObj.img"
                        :disabled="true"
                        size="small"
                        placeholder="请输入文件路径"
                        style="width: 40%"
                    />
                    <el-upload
                        accept="image/jpeg,image/webp,image/png"
                        :action="picApi"
                        :before-upload="beforeAvatarUpload"
                        class="avatar-uploader"
                        :headers="uploadHeader"
                        :on-success="handleAvatarSuccess"
                        :show-file-list="false"
                        style="margin-top: 5px"
                    >
                      <img
                          v-if="dataObj.img"
                          class="avatar"
                          :src="dataObj.img"
                          style="display: block; width: 200px"
                          :v-model="dataObj.img"
                      />
                      <el-button
                          v-else
                          size="small"
                          style="margin: 5px"
                          type="primary"
                      >
                        点击上传
                      </el-button>
                    </el-upload>
                  </el-form-item>
                  <el-form-item v-if="false" label="排序" prop="sort">
                    <el-input
                        v-model="dataObj.sort"
                        :max="8"
                        :min="1"
                        size="small"
                        placeholder="请输入排序数字(1~8)"
                        style="width: 40%"
                        type="number"
                    />
                  </el-form-item>
                  <!--                  <el-form-item>
                                      <el-button type="primary" size="small" @click="updatePrize">
                                        保存
                                      </el-button>
                                    </el-form-item>-->
                </el-form>
              </span>
                </el-tabs>
              </el-tab-pane>
              <el-tab-pane label="规则设置" name="2">
                <el-form-item style="margin: 20px 0;" label="活动名称" prop="">
                  <el-input v-model="form.name" size="small" style="width: 25%"/>
                </el-form-item>
                <el-form-item style="margin-bottom: 20px" label="中奖几率" prop="">
                  <el-input v-model="form.name" size="small" type="number" style="width: 25%">
                    <template slot="prepend">百分之：</template>
                  </el-input>
                </el-form-item>
                <el-form-item style="margin-bottom: 20px" label="参与条件" prop="">
                  <el-radio-group v-model="form.name" size="small" @change="participationConditions ">
                    <el-radio-button label="1">消费可抽</el-radio-button>
                    <el-radio-button label="2">新人</el-radio-button>
                    <el-radio-button label="3">推介注册成功</el-radio-button>
                    <el-radio-button label="4">人人可抽</el-radio-button>
                  </el-radio-group>
                </el-form-item>
                <!--消费可抽-->
                <el-form-item
                    v-show="consumptionPump"
                    style="margin-bottom: 20px"
                    label="抽奖规则"
                    prop=""
                >
                  <span>每次消费可抽奖</span>
                  <el-input-number
                      v-model="form.name"
                      controls-position="right"
                      step-strictly
                      :min="1"
                      style="width: 10%; margin: 0 10px"
                      size="small"
                  />
                  <span>次</span>
                </el-form-item>
                <!--新人-->
                <el-form-item
                    v-show="newcomer"
                    style="margin-bottom: 20px"
                    label="抽奖规则"
                    prop=""
                >
                  <span>新人注册成功即可享有抽奖</span>
                  <el-input-number
                      v-model="form.name"
                      controls-position="right"
                      step-strictly
                      :min="1"
                      style="width: 10%; margin: 0 10px"
                      size="small"
                  />
                  <span>次</span>
                </el-form-item>
                <!--推介注册成功-->
                <el-form-item
                    v-show="referralRegistration"
                    style="margin-bottom: 20px"
                    label="抽奖规则"
                    prop=""
                >
                  <span>每邀请</span>
                  <el-input-number
                      v-model="form.name"
                      controls-position="right"
                      step-strictly
                      :min="1"
                      style="width: 10%; margin: 0 10px"
                      size="small"
                  />
                  <span>人，即获取一次抽奖机会</span>
                </el-form-item>
                <!--人人可抽-->
                <el-form-item
                    v-show="everyoneSmokes"
                    style="margin-bottom: 20px"
                    label="抽奖规则"
                    prop=""
                >
                  <el-select v-model="form.namea" size="small">
                    <el-option label="每天可抽" value="0"></el-option>
                    <el-option label="每人可抽" value="1"></el-option>
                  </el-select>
                  <el-input-number
                      v-model="form.name"
                      controls-position="right"
                      step-strictly
                      :min="1"
                      style="width: 10%; margin: 0 10px"
                      size="small"
                  />
                  <span>次</span>
                </el-form-item>
                <el-form-item style="margin-bottom: 20px" label="消费积分" prop="">
                  <el-input-number
                      v-model="form.name"
                      controls-position="right"
                      step-strictly
                      :min="1"
                      style="width: 25%;"
                      size="small"
                  />
                </el-form-item>
                <el-form-item style="margin-bottom: 20px" label="抽奖上限" prop="">
                  <el-input-number
                      v-model="form.name"
                      controls-position="right"
                      step-strictly
                      :min="0"
                      style="width: 25%;"
                      size="small"
                  />
                </el-form-item>
                <el-form-item style="margin-bottom: 20px" label="活动时间" prop="">
                  <el-date-picker
                      v-model="query_time"
                      size="small"
                      type="daterange"
                      @change="getTimeSection"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      align="right"
                  />
                </el-form-item>
              </el-tab-pane>

              <el-tab-pane label="规则说明" name="3">
                <el-form-item style="margin: 20px 0;" label="规则说明" prop="">
                  <el-input
                      type="textarea"
                      :autosize="{ minRows: 2}"
                      v-model="form.name"
                      style="width: 25%"
                  />
                </el-form-item>
              </el-tab-pane>

              <el-tab-pane label="样式设置" name="4">
                <el-divider content-position="left">文字样式</el-divider>
                <el-form-item label="文字颜色" prop="">
                  <el-color-picker v-model="form.name"/>
                </el-form-item>
                <el-divider content-position="left">抽奖区样式</el-divider>
                <el-form-item label="抽奖区位置" prop="">
                  <el-slider v-model="form.name" show-input :max="10000" style="width: 60%; float: left"/>
                  <span style="float: left; margin-left: 10px">PX</span>
                </el-form-item>
                <el-divider content-position="left">图片样式</el-divider>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="背景图片" prop="">
                      <el-upload
                          accept="image/jpeg,image/webp,image/png"
                          :action="picApi"
                          :before-upload="beforeConfiguration"
                          class="avatar-uploader"
                          :show-file-list="false"
                      >
                        <img
                            v-if="form.bg_pic"
                            class="avatar"
                            :src="form.bg_pic"
                            style="display: block; width: 200px"
                            :v-model="form.bg_pic"
                        />
                        <el-button
                            v-else
                            size="small"
                            style="margin: 5px"
                            type="primary"
                        >
                          点击上传
                        </el-button>
                      </el-upload>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="按钮背景" prop="">
                      <el-upload
                          accept="image/jpeg,image/webp,image/png"
                          :action="picApi"
                          :before-upload="beforeConfigFive"
                          class="avatar-uploader"
                          :show-file-list="false"
                      >
                        <img
                            v-if="form.button"
                            class="avatar"
                            :src="form.button"
                            style="display: block; width: 133px; height: 133px"
                            :v-model="form.button"
                        />
                        <el-button
                            v-else
                            size="small"
                            style="margin: 5px"
                            type="primary"
                        >
                          点击上传
                        </el-button>
                      </el-upload>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="抽奖框" prop="">
                      <el-upload
                          accept="image/jpeg,image/webp,image/png"
                          :action="picApi"
                          :before-upload="beforeConfigFour"
                          class="avatar-uploader"
                          :show-file-list="false"
                      >
                        <img
                            v-if="form.bg_roll"
                            class="avatar"
                            :src="form.bg_roll"
                            style="display: block; width: 200px"
                            :v-model="form.bg_roll"
                        />
                        <el-button
                            v-else
                            size="small"
                            style="margin: 5px"
                            type="primary"
                        >
                          点击上传
                        </el-button>
                      </el-upload>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="弹窗" prop="">
                      <el-upload
                          accept="image/jpeg,image/webp,image/png"
                          :action="picApi"
                          :before-upload="beforeConfigTwo"
                          class="avatar-uploader"
                          :show-file-list="false"
                      >
                        <img
                            v-if="form.bg_pop"
                            class="avatar"
                            :src="form.bg_pop"
                            style="display: block; width: 200px"
                            :v-model="form.bg_pop"
                        />
                        <el-button
                            v-else
                            size="small"
                            style="margin: 5px"
                            type="primary"
                        >
                          点击上传
                        </el-button>
                      </el-upload>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="奖品背景" prop="">
                      <el-upload
                          accept="image/jpeg,image/webp,image/png"
                          :action="picApi"
                          :before-upload="beforeConfigThree"
                          class="avatar-uploader"
                          :show-file-list="false"
                      >
                        <img
                            v-if="form.prize"
                            class="avatar"
                            :src="form.prize"
                            style="display: block; width: 60px; height: 60px"
                            :v-model="form.prize"
                        />
                        <el-button
                            v-else
                            size="small"
                            style="margin: 5px"
                            type="primary"
                        >
                          点击上传
                        </el-button>
                      </el-upload>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-tab-pane>
            </el-tabs>
            <el-form-item>
              <el-button type="primary" @click="submit" size="small">保存</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
  </el-card>
</template>
<script>
import {baseURL} from '@/config'

export default {
  components: {},
  props: [],
  data() {
    return {
      prize_name: '1',
      prize_loading: true,
      dataObj: {
        name: '',
        type: 1,
        img: '',
        prize: '',
        stock: 0,
        sort: 0,
      },
      dataRules: {},
      rules: {},
      radioArr: [
        {index: 1, name: '谢谢参与'},
        {index: 2, name: '金额'},
        {index: 3, name: '优惠券'},
        {index: 4, name: '积分'},
        {index: 5, name: '实物'},
        {index: 6, name: '套餐'},
      ],
      tabPosition: 'left',
      activeName: '1',
      url: ['https://img2.baidu.com/it/u=4050134771,829066775&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=1094'],
      picApi: baseURL + '/base/upload/upload-file',
      consumptionPump: true,
      newcomer: false,
      referralRegistration: false,
      everyoneSmokes: false,
      query_time: [],
      form: {
        name: '1',
      },
    }
  },
  created() {
    this.requestObtain()
  },
  methods: {
    // 参与条件
    participationConditions(event) {
      console.log(event)
      if (event == '1') {
        this.consumptionPump = true
        this.newcomer = false
        this.referralRegistration = false
        this.everyoneSmokes = false
      } else if (event == '2') {
        this.consumptionPump = false
        this.newcomer = true
        this.referralRegistration = false
        this.everyoneSmokes = false
      } else if (event == '3') {
        this.consumptionPump = false
        this.newcomer = false
        this.referralRegistration = true
        this.everyoneSmokes = false
      } else if (event == '4') {
        this.consumptionPump = false
        this.newcomer = false
        this.referralRegistration = false
        this.everyoneSmokes = true
      }
    },
    //获取时间段
    getTimeSection(event) {
      console.log(event)
      if (event != null) {
        this.form.start_date = event[0]
        this.form.end_date = event[1]
      } else {
        this.form.start_date = ''
        this.form.end_date = ''
      }
    },
    //抽奖配置图上传限制(背景图片)
    beforeConfiguration(file) {
      // const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error(
            '图片尺寸限制最小为750 x 1334，比例为1:1，大小不可超过2MB'
        )
        return false
      } else {
        const isSize = new Promise((resolve, reject) => {
          const width = 750
          const height = 1334
          const _URL = window.URL || window.webkitURL
          const img = new Image()
          img.onload = () => {
            const valid =
                img.width >= width &&
                img.height >= height &&
                img.width / img.height == 750 / 1334
            valid ? resolve() : reject()
          }
          img.src = _URL.createObjectURL(file)
        }).then(
            () => {
              return file
            },
            () => {
              this.$message.error(
                  '图片尺寸限制最小为750 x 1334，比例为1:1，大小不可超过2MB'
              )
              return Promise.reject()
            }
        )
        return isSize
      }
    },
    //抽奖配置图上传限制(按钮背景)
    beforeConfigFive(file) {
      // const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error(
            '图片尺寸限制最小为133 x 133，大小不可超过2MB，比例为1:1'
        )
        return false
      } else {
        const isSize = new Promise((resolve, reject) => {
          const width = 133
          const height = 133
          const _URL = window.URL || window.webkitURL
          const img = new Image()
          img.onload = () => {
            const valid =
                img.width >= width &&
                img.height >= height &&
                img.width === img.height
            valid ? resolve() : reject()
          }
          img.src = _URL.createObjectURL(file)
        }).then(
            () => {
              return file
            },
            () => {
              this.$message.error(
                  '图片尺寸限制最小为133 x 133，大小不可超过2MB，比例为1:1'
              )
              return Promise.reject()
            }
        )
        return isSize
      }
    },
    //抽奖配置图上传限制(抽奖框)
    beforeConfigFour(file) {
      // const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error(
            '图片尺寸限制最小为600 x 600，大小不可超过2MB，比例为1:1'
        )
        return false
      } else {
        const isSize = new Promise((resolve, reject) => {
          const width = 600
          const height = 600
          const _URL = window.URL || window.webkitURL
          const img = new Image()
          img.onload = () => {
            const valid =
                img.width >= width &&
                img.height >= height &&
                img.width === img.height
            valid ? resolve() : reject()
          }
          img.src = _URL.createObjectURL(file)
        }).then(
            () => {
              return file
            },
            () => {
              this.$message.error(
                  '图片尺寸限制最小为600 x 600，大小不可超过2MB，比例为1:1'
              )
              return Promise.reject()
            }
        )
        return isSize
      }
    },
    //抽奖配置图上传限制(弹窗)
    beforeConfigTwo(file) {
      // const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error(
            '图片尺寸限制最小为750 x 390，比例为1:1，大小不可超过2MB'
        )
        return false
      } else {
        const isSize = new Promise((resolve, reject) => {
          const width = 750
          const height = 390
          const _URL = window.URL || window.webkitURL
          const img = new Image()
          img.onload = () => {
            const valid =
                img.width >= width &&
                img.height >= height &&
                img.width / img.height == 750 / 390
            valid ? resolve() : reject()
          }
          img.src = _URL.createObjectURL(file)
        }).then(
            () => {
              return file
            },
            () => {
              this.$message.error(
                  '图片尺寸限制最小为750 x 390，比例为1:1，大小不可超过2MB'
              )
              return Promise.reject()
            }
        )
        return isSize
      }
    },
    //抽奖配置图上传限制(奖品背景)
    beforeConfigThree(file) {
      // const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error(
            '图片尺寸限制最小为60 x 60，大小不可超过2MB，比例为1:1'
        )
        return false
      } else {
        const isSize = new Promise((resolve, reject) => {
          const width = 60
          const height = 60
          const _URL = window.URL || window.webkitURL
          const img = new Image()
          img.onload = () => {
            const valid =
                img.width >= width &&
                img.height >= height &&
                img.width === img.height
            valid ? resolve() : reject()
          }
          img.src = _URL.createObjectURL(file)
        }).then(
            () => {
              return file
            },
            () => {
              this.$message.error(
                  '图片尺寸限制最小为60 x 60，大小不可超过2MB，比例为1:1'
              )
              return Promise.reject()
            }
        )
        return isSize
      }
    },
    async requestObtain() {
      const {data} = await getExam()
      this.form.num = data.num
      this.form.content = data.content
    },
    async submit() {
      const {code, message} = await Cac(this.form)
      if (code == '200') {
        this.$message.success(message)
        this.$emit('fetch-data')
        this.close()
      } else {
        this.$message.error(message)
        this.$emit('fetch-data')
        this.close()
      }
    },
  }
}

</script>
